export enum LanguageCodes {
  DANISH = 'dan',
  ENGLISH = 'eng',
  FINNISH = 'fin',
  FRENCH = 'fre',
  ICELANDIC = 'ice',
  ITALIAN = 'ita',
  DUTCH = 'dut',
  NORWEGIAN_BOKMAL = 'nob',
  NORWEGIAN_NYNORSK = 'nno',
  PORTUGUESE = 'por',
  RUSSIAN = 'rus',
  SAMI = 'smi',
  SPANISH = 'spa',
  SWEDISH = 'swe',
  GERMAN = 'ger',
  OTHER = 'mis',
}

export enum LanguageValues {
  DANISH = 'http://lexvo.org/id/iso639-3/dan',
  ENGLISH = 'http://lexvo.org/id/iso639-3/eng',
  FINNISH = 'http://lexvo.org/id/iso639-3/fin',
  FRENCH = 'http://lexvo.org/id/iso639-3/fre',
  ICELANDIC = 'http://lexvo.org/id/iso639-3/ice',
  ITALIAN = 'http://lexvo.org/id/iso639-3/ita',
  DUTCH = 'http://lexvo.org/id/iso639-3/dut',
  NORWEGIAN_BOKMAL = 'http://lexvo.org/id/iso639-3/nob',
  NORWEGIAN_NYNORSK = 'http://lexvo.org/id/iso639-3/nno',
  PORTUGUESE = 'http://lexvo.org/id/iso639-3/por',
  RUSSIAN = 'http://lexvo.org/id/iso639-3/rus',
  SAMI = 'http://lexvo.org/id/iso639-3/smi',
  SPANISH = 'http://lexvo.org/id/iso639-3/spa',
  SWEDISH = 'http://lexvo.org/id/iso639-3/swe',
  GERMAN = 'http://lexvo.org/id/iso639-3/ger',
  OTHER = 'http://lexvo.org/id/iso639-3/mis',
  NONE = '',
}

export const pageLanguages = [LanguageCodes.NORWEGIAN_BOKMAL, LanguageCodes.ENGLISH];

export const publicationLanguages = [
  { id: LanguageCodes.ENGLISH, value: LanguageValues.ENGLISH },
  { id: LanguageCodes.NORWEGIAN_BOKMAL, value: LanguageValues.NORWEGIAN_BOKMAL },
  { id: LanguageCodes.NORWEGIAN_NYNORSK, value: LanguageValues.NORWEGIAN_NYNORSK },
  { id: LanguageCodes.DANISH, value: LanguageValues.DANISH },
  { id: LanguageCodes.FINNISH, value: LanguageValues.FINNISH },
  { id: LanguageCodes.FRENCH, value: LanguageValues.FRENCH },
  { id: LanguageCodes.ICELANDIC, value: LanguageValues.ICELANDIC },
  { id: LanguageCodes.ITALIAN, value: LanguageValues.ITALIAN },
  { id: LanguageCodes.DUTCH, value: LanguageValues.DUTCH },
  { id: LanguageCodes.PORTUGUESE, value: LanguageValues.PORTUGUESE },
  { id: LanguageCodes.RUSSIAN, value: LanguageValues.RUSSIAN },
  { id: LanguageCodes.SAMI, value: LanguageValues.SAMI },
  { id: LanguageCodes.SPANISH, value: LanguageValues.SPANISH },
  { id: LanguageCodes.SWEDISH, value: LanguageValues.SWEDISH },
  { id: LanguageCodes.GERMAN, value: LanguageValues.GERMAN },
  { id: LanguageCodes.OTHER, value: LanguageValues.OTHER },
];
