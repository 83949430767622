export const mockSearchResults: any = {
  offset: '0',
  limit: '10',
  numFound: 8,
  queryTime: 17,
  resourcesAsJson: [
    '{"identifier":"2ee4177a-06ca-4883-8343-16670fad3e1a","features":{"dlr_access":"open","dlr_app":"learning","dlr_content":"Sikt logo farge.jpg","dlr_content_type":"file","dlr_description":"Kort video om hvordan det sympatiske nervesystemet er bygget opp og funger.","dlr_identifier":"2ee4177a-06ca-4883-8343-16670fad3e1a","dlr_resource":"true","dlr_resource_learning":"true","dlr_rights_license_name":"CC BY ND SA 4.0","dlr_status_published":"true","dlr_storage_id":"sikt","dlr_submitter_email":"testpublisher@sikt.no","dlr_time_created":"2020-09-22T10:35:49.376Z","dlr_time_published":"2020-09-22T10:36:01.327Z","dlr_title":"Sikt logo farge","dlr_type":"Video"},"subjects":[],"courses":[],"tags":["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],"types":["learning"],"projects":[],"funders":[],"geographicalCoverages":[],"observationalUnits":[],"processMethods":[],"creators":[{"features":{"dlr_creator_identifier":"57087b9d-46e7-4bd7-9207-1b889a74b2fa","dlr_creator_name":"Publisher Test","dlr_creator_order":"1","dlr_creator_time_created":"2020-09-22T10:35:53.207Z"}}],"contributors":[{"features":{"dlr_contributor_identifier":"c57cd3ae-6925-4515-8416-b4c9a35a9902","dlr_contributor_name":"sikt2","dlr_contributor_time_created":"2020-09-22T10:35:53.208Z","dlr_contributor_type":"HostingInstitution"}}],"accessRead":[],"accessWrite":["testpublisher@sikt.no"]}',
    '{"identifier":"0092d0e7-58a1-4796-b627-dcf8ab459328","features":{"dlr_access":"open","dlr_app":"learning","dlr_content":"stor_video.mp4","dlr_content_type":"file","dlr_identifier":"0092d0e7-58a1-4796-b627-dcf8ab459328","dlr_resource":"true","dlr_resource_learning":"true","dlr_submitter_email":"nr@sikt.no","dlr_time_created":"2020-10-08T10:33:07.994Z","dlr_title":"Test av chunky","dlr_type":"Audiovisual"},"subjects":[],"courses":[],"tags":[],"types":["learning"],"projects":[],"funders":[],"geographicalCoverages":[],"observationalUnits":[],"processMethods":[],"creators":[{"features":{"dlr_creator_identifier":"d9d83cff-9b39-4d6b-b62a-d44852ed4291","dlr_creator_name":"Nikolai Fikse Raanes","dlr_creator_order":"1","dlr_creator_time_created":"2020-10-08T10:38:06.578Z"}}],"contributors":[{"features":{"dlr_contributor_identifier":"fb8e50e2-e2f7-4957-94a6-76f102962dc3","dlr_contributor_name":"sikt","dlr_contributor_time_created":"2020-10-08T10:38:06.616Z","dlr_contributor_type":"HostingInstitution"}}],"accessRead":[],"accessWrite":["nr@sikt.no"]}',
    '{"identifier":"cf1a2380-ce73-4f44-ad74-8e30c0d51399","features":{"dlr_access":"open","dlr_app":"learning","dlr_content":"UiBlogo_gray_v.png","dlr_content_type":"file","dlr_identifier":"cf1a2380-ce73-4f44-ad74-8e30c0d51399","dlr_resource":"true","dlr_resource_learning":"true","dlr_rights_license_name":"CC BY 4.0","dlr_status_published":"true","dlr_storage_id":"sikt","dlr_submitter_email":"nr@sikt.no","dlr_time_created":"2019-07-05T10:15:12.496Z","dlr_time_published":"2019-07-05T10:15:15.221Z","dlr_title":"UiBlogo gray v","dlr_type":"document"},"subjects":[],"courses":[],"tags":[],"types":["learning"],"projects":[],"funders":[],"geographicalCoverages":[],"observationalUnits":[],"processMethods":[],"creators":[{"features":{"dlr_creator_identifier":"eafcf491-de33-4dde-92b5-d85b2fc53abb","dlr_creator_name":"Nummer 1","dlr_creator_time_created":"2019-07-05T10:15:12.888Z"}},{"features":{"dlr_creator_identifier":"3b0a5c57-b69e-4b9d-9cbd-a71d7ac8ab34","dlr_creator_name":"test","dlr_creator_time_created":"2019-07-05T10:15:12.936Z"}},{"features":{"dlr_creator_identifier":"eebb0627-f1a1-46b9-ab3a-7fb6355cfa7a","dlr_creator_name":"Nikolai Fikse Raanes2","dlr_creator_time_created":"2019-07-05T10:15:12.845Z"}}],"contributors":[],"accessRead":[],"accessWrite":["nr@sikt.no"]}',
    '{"identifier":"47bee7c5-696f-47d1-bde5-0c7fd9674f69","features":{"dlr_access":"open","dlr_app":"learning","dlr_content":"UiBlogo_hvit_h.png","dlr_content_type":"file","dlr_identifier":"47bee7c5-696f-47d1-bde5-0c7fd9674f69","dlr_resource":"true","dlr_resource_learning":"true","dlr_rights_license_name":"CC BY 4.0","dlr_status_published":"true","dlr_storage_id":"sikt","dlr_submitter_email":"nr@sikt.no","dlr_time_created":"2019-07-05T10:15:13.372Z","dlr_time_published":"2019-07-05T10:15:15.390Z","dlr_title":"UiBlogo hvit h","dlr_type":"Image"},"subjects":[],"courses":[],"tags":[],"types":["learning"],"projects":[],"funders":[],"geographicalCoverages":[],"observationalUnits":[],"processMethods":[],"creators":[{"features":{"dlr_creator_identifier":"b3b1fe63-f661-40fd-b843-f14ac9694673","dlr_creator_name":"Nummer 1","dlr_creator_time_created":"2019-07-05T10:15:13.614Z"}},{"features":{"dlr_creator_identifier":"d3461060-8037-4fe2-bc27-b2ea7e382d86","dlr_creator_name":"Nikolai Fikse Raanes","dlr_creator_time_created":"2019-07-05T10:15:13.661Z"}},{"features":{"dlr_creator_identifier":"34c4bd87-85dc-4893-98a0-c7d4ca9e869c","dlr_creator_name":"test","dlr_creator_time_created":"2019-07-05T10:15:13.707Z"}}],"contributors":[],"accessRead":[],"accessWrite":["nr@sikt.no"]}',
    '{"identifier":"4cfadf09-19a8-4734-bde8-582799f66c44","features":{"dlr_access":"open","dlr_app":"learning","dlr_content":"324-1 (1).jpg","dlr_content_type":"file","dlr_identifier":"4cfadf09-19a8-4734-bde8-582799f66c44","dlr_identifier_handle":"https://hdl.handle.net/11250.1/38890987","dlr_resource":"true","dlr_resource_learning":"true","dlr_rights_license_name":"CC BY 4.0","dlr_status_published":"true","dlr_storage_id":"sikt","dlr_subject_nsi_id":"220","dlr_subject_nsi_name":"Sosiologi","dlr_submitter_email":"nr@sikt.no","dlr_time_created":"2019-04-05T10:10:49.620Z","dlr_time_published":"2019-04-05T10:11:08.326Z","dlr_title":"Et bilde","dlr_type":"Image"},"subjects":["Sosiologi","Samfunnsvitenskap"],"courses":[],"tags":["lego","test"],"types":["learning"],"projects":[],"funders":[],"geographicalCoverages":[],"observationalUnits":[],"processMethods":[],"creators":[{"features":{"dlr_creator_identifier":"62154026-2936-4eae-8707-69f4c4465c34","dlr_creator_name":"Nikolai Fikse Raanes","dlr_creator_time_created":"2019-04-05T10:10:59.624Z"}}],"contributors":[],"accessRead":[],"accessWrite":["nr@sikt.no"]}',
    '{"identifier":"e1bd2551-b931-4025-b055-9433dc2b81c1","features":{"dlr_access":"open","dlr_app":"learning","dlr_content":"testzip.zip","dlr_content_type":"file","dlr_identifier":"e1bd2551-b931-4025-b055-9433dc2b81c1","dlr_identifier_handle":"https://hdl.handle.net/11250.1/38892494","dlr_resource":"true","dlr_resource_learning":"true","dlr_rights_license_name":"CC BY 4.0","dlr_status_published":"true","dlr_storage_id":"sikt","dlr_submitter_email":"nr@sikt.no","dlr_time_created":"2019-07-03T11:10:26.617Z","dlr_time_published":"2019-07-05T10:03:06.914Z","dlr_title":"testzip","dlr_type":"Other"},"subjects":[],"courses":[],"tags":[],"types":["learning"],"projects":[],"funders":[],"geographicalCoverages":[],"observationalUnits":[],"processMethods":[],"creators":[{"features":{"dlr_creator_identifier":"639254a8-d86a-475b-8486-88d21f1b1f4a","dlr_creator_name":"Nummer 1","dlr_creator_order":"1","dlr_creator_time_created":"2019-07-03T11:10:29.939Z"}},{"features":{"dlr_creator_identifier":"8f4e1aae-72dd-4998-b30f-57e066212710","dlr_creator_name":"Nummer 4","dlr_creator_order":"4","dlr_creator_time_created":"2019-07-05T10:02:43.796Z"}},{"features":{"dlr_creator_identifier":"bc952958-c820-4a36-9cee-c5a61b2f0c22","dlr_creator_name":"Nymmer 3","dlr_creator_order":"3","dlr_creator_time_created":"2019-07-05T08:30:19.135Z"}},{"features":{"dlr_creator_identifier":"22439966-13e5-4d8c-96c6-07a0788d7770","dlr_creator_name":"Nummer 2","dlr_creator_order":"2","dlr_creator_time_created":"2019-07-04T12:18:54.830Z"}},{"features":{"dlr_creator_identifier":"8f810485-f15d-47f1-a0b3-53ab9e77656c","dlr_creator_name":"Nummer 5","dlr_creator_order":"5","dlr_creator_time_created":"2019-07-05T10:02:49.263Z"}}],"contributors":[{"features":{"dlr_contributor_identifier":"c70f05a4-d546-49ca-892f-7877bbc338df","dlr_contributor_name":"test","dlr_contributor_time_created":"2019-07-05T07:33:52.315Z","dlr_contributor_type":"ContactPerson"}}],"accessRead":[],"accessWrite":["nr@sikt.no"]}',
    '{"identifier":"9c721d54-a861-49b2-884b-69be4ce4aa36","features":{"dlr_access":"open","dlr_app":"learning","dlr_content":"UiBlogo_hvit_m.png","dlr_content_type":"file","dlr_identifier":"9c721d54-a861-49b2-884b-69be4ce4aa36","dlr_identifier_handle":"https://hdl.handle.net/11250.1/38892497","dlr_resource":"true","dlr_resource_learning":"true","dlr_rights_license_name":"CC BY 4.0","dlr_status_published":"true","dlr_storage_id":"sikt","dlr_submitter_email":"nr@sikt.no","dlr_time_created":"2019-07-05T10:15:13.933Z","dlr_time_published":"2019-07-05T10:15:15.581Z","dlr_title":"UiBlogo hvit m","dlr_type":"Image"},"subjects":[],"courses":[],"tags":[],"types":["learning"],"projects":[],"funders":[],"geographicalCoverages":[],"observationalUnits":[],"processMethods":[],"creators":[{"features":{"dlr_creator_identifier":"23c63302-9bdb-4caa-824b-c8abbf5b64ce","dlr_creator_name":"test","dlr_creator_time_created":"2019-07-05T10:15:14.285Z"}},{"features":{"dlr_creator_identifier":"43a044eb-fc79-4397-a028-12664e02f807","dlr_creator_name":"Nummer 1","dlr_creator_time_created":"2019-07-05T10:15:14.244Z"}},{"features":{"dlr_creator_identifier":"e34edaf4-45f4-481f-a970-30b91da29cb1","dlr_creator_name":"Nikolai Fikse Raanes","dlr_creator_time_created":"2019-07-05T10:15:14.197Z"}}],"contributors":[],"accessRead":[],"accessWrite":["nr@sikt.no"]}',
    '{"identifier":"3af31e69-38f6-4100-8ba5-7d1cd79a0ad4","features":{"dlr_access":"open","dlr_app":"learning","dlr_content":"UiBlogo_hvit_v.png","dlr_content_type":"file","dlr_identifier":"3af31e69-38f6-4100-8ba5-7d1cd79a0ad4","dlr_identifier_handle":"https://hdl.handle.net/11250.1/38892498","dlr_resource":"true","dlr_resource_learning":"true","dlr_rights_license_name":"CC BY 4.0","dlr_status_published":"true","dlr_storage_id":"sikt","dlr_submitter_email":"nr@sikt.no","dlr_time_created":"2019-07-05T10:15:14.488Z","dlr_time_published":"2019-07-05T10:15:15.748Z","dlr_title":"UiBlogo hvit v","dlr_type":"Image"},"subjects":[],"courses":[],"tags":[],"types":["learning"],"projects":[],"funders":[],"geographicalCoverages":[],"observationalUnits":[],"processMethods":[],"creators":[{"features":{"dlr_creator_identifier":"3bf00bbd-6202-469e-b6a2-fedae0a173ed","dlr_creator_name":"Nikolai Fikse Raanes","dlr_creator_order":"2","dlr_creator_time_created":"2019-07-05T10:15:14.798Z"}},{"features":{"dlr_creator_identifier":"3ac84623-4acb-486c-95b3-6a533ed0eb18","dlr_creator_name":"Nummer 1","dlr_creator_order":"1","dlr_creator_time_created":"2019-07-05T10:15:14.759Z"}},{"features":{"dlr_creator_identifier":"884a4a46-cb9a-4d57-b40d-56d355ecc936","dlr_creator_name":"test","dlr_creator_order":"3","dlr_creator_time_created":"2019-07-05T10:15:14.842Z"}}],"contributors":[],"accessRead":[],"accessWrite":["nr@sikt.no"]}',
  ],
  facet_counts: [],
  spellcheck_suggestions: [],
};

export const mockSearchResultsNoResult: any = {
  offset: '0',
  limit: '10',
  numFound: 0,
  queryTime: 17,
  resourcesAsJson: [],
  facet_counts: [],
  spellcheck_suggestions: ['risk', 'risiko'],
};
